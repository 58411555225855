import {t} from "i18next";
import {personName} from "../utilities";
import React from "react";
import {displayNotification} from "../displayNotification";

/**
 * Notifications when sessions inconsistencies are found
 * @param inconsistenciesList the list of inconsistencies ({type, entity, entitiesInvolved})
 */
export const displaySessionsInconsistenciesNotifications = (inconsistenciesList) => {
  if (inconsistenciesList.length > 0) {
    const listOfMessages = [];
    for (let inconsistency of inconsistenciesList) {
      for (let inconsistencyDetail of inconsistency.inconsistencies) {
        let message;
        if (inconsistencyDetail.type === "availabilitiesOverlap") {
          switch (inconsistencyDetail.entity) {
            case "project":
              message = t("sessions:inconsistenciesNotification.isOutOfProjectSlots");
              break;
            case "place":
              message = t("sessions:inconsistenciesNotification.isOutOfPlaceSlots", {
                entitiesInvolved: inconsistencyDetail.entitiesInvolved
                  .map((e) => e.name)
                  .join(", "),
              });
              break;
            case "steward":
              message = t("sessions:inconsistenciesNotification.isOutOfStewardSlots", {
                entitiesInvolved: inconsistencyDetail.entitiesInvolved.map(personName).join(", "),
              });
              break;
            default:
          }
        } else if (inconsistencyDetail.type === "alreadyUsedEntity") {
          switch (inconsistencyDetail.entity) {
            case "place":
              message = t("sessions:inconsistenciesNotification.placesConflictAtTheSameTime", {
                entitiesInvolved: inconsistencyDetail.entitiesInvolved
                  .map((e) => e.name)
                  .join(", "),
              });
              break;
            case "steward":
              message = t("sessions:inconsistenciesNotification.stewardConflictAtTheSameTime", {
                entitiesInvolved: inconsistencyDetail.entitiesInvolved.map(personName).join(", "),
              });
              break;
            default:
          }
        }
        listOfMessages.push(
          t("sessions:inconsistenciesNotification.theSlotNumberMessage", {
            slotNumber: inconsistency.slot,
            message,
          })
        );
      }
    }

    displayNotification("open", `inconsistency-${inconsistenciesList[0].session}`, {
      message: t("sessions:inconsistenciesNotification.title"),
      description: (
        <ul>
          {listOfMessages.map((message) => (
            <li>{message}</li>
          ))}
        </ul>
      ),
      duration: 5 + inconsistenciesList.length * 2,
    });
  }
};
