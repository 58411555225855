// Handles dates when end date is at midnight o'clock. Without it, any session ending at midnight will
// overflow on the next day, which is really annoying. This manager aims to remove this behavior.
import dayjs from "dayjs";

export const midnightDateManager = {
  dataToDisplay: (slot) => {
    // If end date is midnight, remove one second
    let endDate = dayjs(slot.endDate);
    if (endDate.hour() === 0 && endDate.minute() === 0) endDate = endDate.subtract(1, "minute");
    return {...slot, endDate: endDate.format()};
  },
  displayToData: (existingEndDate, newEndDate) => {
    // If end date is midnight, remove one minute
    const endDate = dayjs(existingEndDate);
    newEndDate = dayjs(newEndDate);
    if (endDate.hour() === 0 && endDate.minute() === 0) newEndDate = newEndDate.add(1, "minute");
    return newEndDate;
  },
};
