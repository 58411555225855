import {createSlice} from "@reduxjs/toolkit";
import {currentProjectActions} from "./currentProject.js";
import {usersActions} from "./users.js";
import {projectsActions} from "./projects";
import {viewActions} from "./view";
import {registrationsActions} from "./registrations";
import {URLS} from "../app/configuration";
import i18n, {t} from "i18next";
import {changeSentryUser} from "../app/services/sentry";
import {fetchWithMessages} from "../utils/api/fetchWithMessages";

export const currentUserSlice = createSlice({
  name: "currentUser",
  initialState: {
    user: {},
    connected: undefined,
    connectionError: undefined,
    connectionNotice: undefined,
  },
  reducers: {
    changeLogin: (state, action) => {
      state.user.email = action.payload;
    },
    changeLastName: (state, action) => {
      state.user.lastName = action.payload;
    },
    changeFirstName: (state, action) => {
      state.user.firstName = action.payload;
    },
    changeUser: (state, action) => {
      state.user = action.payload;
      changeSentryUser(state.user);
    },
    changePassword: (state, action) => {
      state.user.password = action.payload;
    },
    changeConnected: (state, action) => {
      state.connected = action.payload;
    },
    changeConnectionError: (state, action) => {
      state.connectionError = action.payload;
    },
    changeConnectionNotice: (state, action) => {
      state.connectionNotice = action.payload;
    },
    reset: (state, action) => {
      state.user = {};
      changeSentryUser(state.user);
      state.connected = false;
    },
  },
});

const asyncActions = {
  signUp: () => async (dispatch, getState) => {
    const state = getState();

    try {
      await fetchWithMessages(
        "users",
        {
          noAuthNeeded: true,
          method: "POST",
          body: {...state.currentUser.user, locale: i18n.language},
        },
        {},
        false
      );
      dispatch(
        currentUserActions.changeConnectionNotice(t("users:alerts.accountSuccessfullyCreated"))
      );
      return true;
    } catch (e) {
      dispatch(currentUserActions.changeConnectionError(t("users:alerts.emailAlreadyInUse")));
      return false;
    }
  },
  logIn: () => async (dispatch, getState) => {
    const state = getState();
    try {
      const data = await fetchWithMessages(
        "auth/authenticate",
        {
          noAuthNeeded: true,
          method: "POST",
          body: state.currentUser.user,
        },
        {200: t("users:messages.logInSuccessful")},
        false
      );

      localStorage.setItem("token", data.jwt_token);
      dispatch(currentProjectActions.cleanProject());
      dispatch(currentUserActions.changeConnected(undefined));
    } catch (e) {
      dispatch(
        currentUserActions.changeConnectionError(t("users:alerts.emailAndPasswordDontMatch"))
      );
    }
  },
  logOut: () => async (dispatch, getState) => {
    dispatch(currentProjectActions.cleanProject());
    dispatch(viewActions.setSearchParams({}));
    dispatch(projectsActions.cleanProjectList());
    localStorage.removeItem("token");
    dispatch(currentUserActions.reset());
    dispatch(registrationsActions.setCurrent(undefined));
  },
  refreshAuthTokens: () => async (dispatch, getState) => {
    // If no login token, then it means the user should disconnect, that's all
    if (!localStorage.getItem("token")) {
      dispatch(currentUserActions.changeConnected(false));
      return;
    }

    try {
      // If there is a login token, check it
      const data = await fetchWithMessages(
        "auth/refreshAuthTokens",
        {method: "GET"},
        {
          401: {
            type: "info",
            message: t("users:messages.pleaseReconnectToYourAccount"),
          },
        }
      );

      // Change connection status and store the connection token
      localStorage.setItem("token", data.jwt_token);
      dispatch(currentUserActions.changeConnected(true));

      // Change the connected currentUser
      dispatch(currentUserActions.changeUser(data.user));
      dispatch(usersActions.changeEditing(data.user));
    } catch (e) {
      dispatch(currentUserActions.changeConnected(false));
    }
  },
  forgotPassword: () => async (dispatch, getState) => {
    const state = getState();

    fetchWithMessages("auth/password/sendResetEmail", {
      noAuthNeeded: true,
      noResponseData: true,
      method: "POST",
      queryParams: {lang: i18n.language},
      body: {
        email: state.currentUser.user.email,
        url: URLS.CURRENT,
      },
    }).then(() => {
      dispatch(currentProjectActions.cleanProject());
      dispatch(
        currentUserActions.changeConnectionNotice(t("users:alerts.passwordResetEmailSentNotice"))
      );
    });
  },
  checkPasswordResetToken: (token) => async (dispatch, getState) => {
    fetchWithMessages(`auth/password/checkResetToken/${token}`, {
      noAuthNeeded: true,
      noResponseData: true,
      method: "GET",
    })
      .then(() => {
        dispatch(currentProjectActions.cleanProject());
      })
      .catch(() =>
        dispatch(
          currentUserActions.changeConnectionError(t("users:alerts.paswordResetTokenInvalid"))
        )
      );
  },
  resetPassword: (token) => async (dispatch, getState) => {
    const state = getState();

    try {
      await fetchWithMessages("auth/password/reset", {
        noAuthNeeded: true,
        noResponseData: true,
        method: "POST",
        body: {
          token: token,
          password: state.currentUser.user.password,
        },
      });

      dispatch(currentProjectActions.cleanProject());
      dispatch(currentUserActions.changeConnectionNotice(t("users:alerts.passwordHasBeenChanged")));
      return true;
    } catch (e) {
      dispatch(
        currentUserActions.changeConnectionError(t("users:alerts.errorWhenChangingPassword"))
      );
      return false;
    }
  },
  deleteAccount: () => async (dispatch, getState) => {
    const state = getState();
    await fetchWithMessages(
      `users/${state.currentUser.user._id}`,
      {method: "DELETE", noResponseData: true},
      {200: t("users:messages.accountSuccessfullyDeleted")}
    );
    dispatch(currentUserActions.logOut());
  },
};

export const currentUserSelectors = {
  selectUser: (state) => state.currentUser.user,
  // Alias for selectUser: in orga-front, the authenticated user is always the same
  // as the current user (can be different in inscription-front)
  selectAuthenticatedUser: (state) => state.currentUser.user,
  selectConnected: (state) => state.currentUser.connected,
  selectConnectionError: (state) => state.currentUser.connectionError,
  selectConnectionNotice: (state) => state.currentUser.connectionNotice,
};

export const currentUserReducer = currentUserSlice.reducer;

export const currentUserActions = {
  ...currentUserSlice.actions,
  ...asyncActions,
};
