// @ts-ignore
import logoColors from "./images/logo-colors.svg";
// @ts-ignore
import logoColorsWithWhiteText from "./images/logo-colors-with-white-text.svg";
// @ts-ignore
import {WEBSITE_URL} from "./../style/sharedConfig";

// Base default spinner logo to take
export const defaultSpinnerLogo = logoColors;

// Logo to display in the AuthPage
export const connectionPageLogo = logoColorsWithWhiteText;

export const connectionPageBackground = "var(--noe-bg)";

// Instance name
export const instanceName = process.env.REACT_APP_INSTANCE_NAME || "NOÉ";

// URLs
export const URLS = {
  ORGA_FRONT: process.env.REACT_APP_ORGA_FRONT_URL as string,
  INSCRIPTION_FRONT: process.env.REACT_APP_INSCRIPTION_FRONT_URL as string,
  API: process.env.REACT_APP_API_URL as string,
  WEBSITE: WEBSITE_URL as string,

  CURRENT: process.env.REACT_APP_ORGA_FRONT_URL as string,
} as const;
