import {createSlice} from "@reduxjs/toolkit";
import {sessionsActions} from "./sessions";
import {OFFLINE_MODE} from "../utils/offlineModeUtilities";
import {createCustomEntityAdapter} from "../utils/features/customEntityAdapter";
import {persistEntityInBackend} from "../utils/api/persistEntityInBackend";
import {removeEntityInBackend} from "../utils/api/removeEntityInBackend";
import {loadEntityFromBackend} from "../utils/api/loadEntityFromBackend";
import {loadListFromBackend} from "../utils/api/loadListFromBackend";
import {
  resetDependenciesContext,
  shouldAutoRefreshDependencies,
} from "../utils/features/featuresUtilities";
import {LoadListParams} from "../utils/features/types";
import {sorter} from "../utils/sorters";
import {EntitiesSelectors} from "../utils/features/types";

const activitiesAdapter = createCustomEntityAdapter({
  selectId: (el) => el._id,
  sortComparer: (a, b) => sorter.text(a.name, b.name),
});

export const activitiesSlice = createSlice({
  name: "activities",
  initialState: activitiesAdapter.getInitialState({
    init: {status: "idle"},
    editing: {},
  }),
  reducers: {
    ...activitiesAdapter.reducers,
    changeSlotsToEditing: (state, action) => {
      state.editing = {
        ...state.editing,
        slots: action.payload,
      };
    },
    addStewardsToEditing: (state, action) => {
      state.editing.stewards.push(...action.payload);
    },
    removeStewardToEditing: (state, action) => {
      state.editing.stewards = state.editing.stewards.filter((t) => t._id !== action.payload._id);
    },
    addPlacesToEditing: (state, action) => {
      state.editing.places.push(...action.payload);
    },
    removePlaceToEditing: (state, action) => {
      state.editing.places = state.editing.places.filter((t) => t._id !== action.payload._id);
    },
  },
});

const asyncActions = {
  loadList:
    ({forceLoad, silent}: LoadListParams = {}) =>
    async (dispatch, getState) => {
      const state = getState();
      const projectId = state.currentProject.project._id;

      await loadListFromBackend(
        "activities",
        projectId,
        state.activities.init,
        () => dispatch(activitiesActions.initContext(projectId)),
        (data) => {
          dispatch(activitiesActions.initList({list: data, project: projectId}));
          // If there were some new changes (using forceLoad), also force update of dependencies
          shouldAutoRefreshDependencies(forceLoad, data) &&
            resetDependenciesContext(dispatch, sessionsActions);
        },
        forceLoad,
        !silent
      );
    },
  loadEditing: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    return loadEntityFromBackend(
      "activities",
      entityId,
      projectId,
      state.activities.editing,
      () =>
        dispatch(
          activitiesActions.setEditing({
            _id: "new",
            stewards: [],
            places: [],
          })
        ),
      (data) => dispatch(activitiesActions.setEditing(data)),
      {
        notFoundAction: () =>
          OFFLINE_MODE &&
          dispatch(activitiesActions.setEditing(state.activities.entities[entityId])),
      }
    );
  },
  persist: (fieldsToUpdate?: any) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id || fieldsToUpdate.project; // If no project id, fll back on the fields given

    // If some fields are given as argument, directly take this to update the registration
    const payload = fieldsToUpdate || state.activities.editing;

    return persistEntityInBackend(
      "activities",
      {...payload, project: projectId},
      projectId,
      (data) => dispatch(activitiesActions.addToList(data)),
      (data) => {
        dispatch(activitiesActions.updateInList(data));
        resetDependenciesContext(dispatch, sessionsActions);
      }
    );
  },
  remove: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    await removeEntityInBackend(
      "activities",
      entityId,
      projectId,
      activitiesSelectors.selectList(state),
      () => dispatch(activitiesActions.removeFromList(entityId))
    );
  },
  loadEditingHistory: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    !OFFLINE_MODE &&
      (await loadEntityFromBackend(
        "activities",
        `${entityId}/history`,
        projectId,
        state.activities.editing,
        null,
        (data) => dispatch(activitiesActions.changeEditing({history: data}))
      ));
  },
};

const activitiesAdapterSelectors = activitiesAdapter.getSelectors((state) => state.activities);

export const activitiesSelectors: EntitiesSelectors<any, any> = {
  selectEditing: (state) => state.activities.editing,
  selectList: activitiesAdapterSelectors.selectAll,
  selectById: activitiesAdapterSelectors.selectEntities,
  selectIsLoaded: (state) => state.activities.init.status === "loaded",
};

export const activitiesReducer = activitiesSlice.reducer;

export const activitiesActions = {
  ...activitiesSlice.actions,
  ...asyncActions,
};
