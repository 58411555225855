import {useDispatch, useSelector} from "react-redux";
import {viewActions, viewSelectors} from "../../../features/view";
import {currentProjectSelectors} from "../../../features/currentProject";
import {useWindowDimensions} from "../../../common/hooks/useWindowDimensions";
import {getNumberOfDaysToDisplay} from "../../../utils/agendaUtilities";
import {useCallback} from "react";
import type {CustomFieldFilter} from "../atoms/filterSessionsByCustomFields";
type ResourceName = "places" | "stewards" | "category";
type ResourceMappingId = "placeId" | "stewardId" | "categoryId";

export const resourcesMapping: Record<ResourceName, ResourceMappingId> = {
  places: "placeId",
  stewards: "stewardId",
  category: "categoryId",
};

/**
 * Agenda display parameter options.
 */
export type AgendaParamsProps = {
  /**
   * Height of agenda cells.
   */
  cellDisplayHeight: number;

  /**
   * Start date to display agenda from. In UNIX format.
   */
  currentAgendaDate: number;

  /**
   * Whether to display appointments overlapping on each other or the one next to each other.
   */
  slotsOnEachOther: boolean;

  /**
   * The number of days to display in the agenda
   */
  numberOfDaysDisplayed: number;

  /**
   * Categories to filter appointments by.
   */
  categoriesFilter: string[];

  /**
   * If you want to force the start hours in the agenda display
   */
  forcedDisplayHours: {
    start: number;
    end: number;
  };

  /**
   * Whether to group by days or resources first.
   */
  groupByDaysFirst: boolean;

  /**
   * Custom fields filtering
   */
  customFieldsFilters: Array<CustomFieldFilter>;

  /**
   * Default duration when creating new sessions slots.
   */
  defaultNewSlotDuration: number;

  /**
   * Whether to show resource availabilities, for each resource.
   */
  showResourcesAvailabilities: {
    [key in ResourceMappingId]: boolean;
  };

  /**
   * What to show on appointment cards.
   */
  showResourcesListingOnAgendaCards: (
    | "places"
    | "stewards"
    | "registrations"
    | "maxNumberOfParticipants"
    | "activityTags"
    | "sessionTags"
  )[];

  /**
   * Selected resources to group by.
   */
  selectedResources: ResourceMappingId[];

  /**
   * Resource and appointment filters.
   */
  resourcesFilterSelections: {
    [key in ResourceName]: any;
  };

  /**
   * Search bar value and research scopes
   */
  searchBarValue: {
    text: string;
    scopes: string[];
  };
};

export const useAgendaParams = () => {
  const dispatch = useDispatch();
  const agendaParams: AgendaParamsProps = useSelector(viewSelectors.selectAgendaParams);
  const currentProject = useSelector(currentProjectSelectors.selectProject);
  const {windowWidth} = useWindowDimensions();

  const setAgendaParams = useCallback(
    (params: Partial<AgendaParamsProps>) => {
      dispatch(viewActions.changeAgendaParams({projectId: currentProject._id, params}));
    },
    [currentProject]
  );
  // Transform array of resources to display
  const resourcesListingsToDisplay = Object.fromEntries(
    agendaParams.showResourcesListingOnAgendaCards.map((resourceName) => [resourceName, true])
  );

  // Number of days to display. Will be set asynchronously in useEffect depending on the number of places and other params
  const numberOfDaysDisplayed =
    agendaParams?.numberOfDaysDisplayed || getNumberOfDaysToDisplay(windowWidth, currentProject);

  return [
    {...agendaParams, resourcesListingsToDisplay, numberOfDaysDisplayed},
    setAgendaParams,
  ] as const;
};
