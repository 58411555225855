import {createSelector, createSlice} from "@reduxjs/toolkit";
import {currentProjectActions} from "./currentProject";
import {sessionsActions} from "./sessions";
import {activitiesActions} from "./activities";
import {placesActions} from "./places";
import {stewardsActions} from "./stewards";
import {categoriesActions} from "./categories";
import {registrationsActions} from "./registrations";
import {currentUserActions} from "./currentUser";
import {OFFLINE_MODE} from "../utils/offlineModeUtilities";
import {Modal} from "antd";
import {teamsActions} from "./teams";
import {persistEntityInBackend} from "../utils/api/persistEntityInBackend";
import {removeEntityInBackend} from "../utils/api/removeEntityInBackend";
import {loadEntityFromBackend} from "../utils/api/loadEntityFromBackend";
import {loadListFromBackend} from "../utils/api/loadListFromBackend";
import {fetchWithMessages} from "../utils/api/fetchWithMessages";
import {flattenFieldComponents} from "../utils/registrationsUtilities";

// Opening state enum
export const OpeningState = {
  notOpened: 0,
  preRegisterOnly: 1,
  registerForStewardsOnly: 2,
  registerForAll: 3,
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    list: [],
    init: false,
    editing: {},
  },
  reducers: {
    updateInList: (state, action) => {
      state.list = [action.payload, ...state.list.filter((i) => i._id !== action.payload._id)];
    },
    initList: (state, action) => {
      state.init = !!action.payload;
      state.list = action.payload || [];
    },
    changeEditing: (state, action) => {
      state.editing = {
        ...state.editing,
        ...action.payload,
      };
    },
    changeFormComponent: (state, action) => {
      state.editing.formComponents.splice(action.payload.index, 1, action.payload.values);
    },
    setEditing: (state, action) => {
      state.editing = action.payload;
    },
    clean: (state) => {
      state.list = [];
      state.init = false;
      state.editing = {};
    },
  },
});

const asyncActions = {
  loadList: () => async (dispatch, getState) => {
    const state = getState();

    if (!state.projects.init) {
      await loadListFromBackend(
        "projects", // Load projects: no need for an additional endpoint
        undefined,
        state.projects.init,
        null, // No need because no existing function initContext
        (data) => dispatch(projectsActions.initList(data))
      );
    }
  },
  loadEditing: (entityId) => async (dispatch, getState) => {
    // this function is not called in the project layout, only in the ProjectEdit page, that appears
    // only when creating a project. Otherwise, it's the currentProjectActions.loadEditing
    // function that is called
    const state = getState();

    return loadEntityFromBackend(
      "projects",
      entityId,
      undefined,
      state.projects.editing,
      () => dispatch(projectsActions.setEditing({_id: "new", usePlaces: true, useTeams: false})),
      (data) => dispatch(projectsActions.setEditing(data))
    );
  },
  persist: (fieldsToUpdate?: any) => async (dispatch, getState) => {
    const state = getState();

    // If some fields are given as argument, directly take this to update the registration
    const payload = {...state.projects.editing, ...fieldsToUpdate};

    return persistEntityInBackend(
      "projects",
      payload,
      undefined,
      (data) => {
        dispatch(currentUserActions.refreshAuthTokens()); // Reload the user and all its registrations
        dispatch(projectsActions.updateInList(data)); //
      },
      (data) => {
        dispatch(projectsActions.updateInList(data));
        dispatch(projectsActions.setEditing(data));
        dispatch(currentProjectActions.changeProject(data));
      }
    );
  },
  remove: (projectId) => async (dispatch, getState) => {
    const state = getState();

    return await removeEntityInBackend(
      "projects",
      projectId || state.projects.editing._id,
      undefined,
      state.projects.list,
      (newProjectsList) => {
        dispatch(projectsActions.initList(newProjectsList));
      }
    );
  },
  loadEditingHistory: (entityId) => async (dispatch, getState) => {
    const state = getState();

    !OFFLINE_MODE &&
      (await loadEntityFromBackend(
        "projects",
        `${entityId}/history`,
        null,
        state.places.editing,
        null,
        (data) => dispatch(projectsActions.changeEditing({history: data}))
      ));
  },
  export: (withRegistrations, anonymized, keepStewardNames) => async (dispatch, getState) => {
    const state = getState();
    const existingId = state.projects.editing._id;

    try {
      const data = await fetchWithMessages(`projects/${existingId}/export`, {
        method: "GET",
        queryParams: {withRegistrations, anonymized, keepStewardNames},
      });

      const {createdAt, id, _id, __v, updatedAt, ...project} = data.project;

      return {...data, project};
    } catch (e) {
      console.log(e);
      return Promise.reject();
    }
  },
  import:
    (dataToImport, additiveImport = true, withRegistrations) =>
    async (dispatch, getState) => {
      const state = getState();
      const existingId = state.projects.editing._id;

      try {
        const data = await fetchWithMessages(
          `projects/${existingId}/import`,
          {
            method: "POST",
            queryParams: {additiveImport, withRegistrations},
            body: dataToImport,
          },
          undefined,
          "L'import a échoué. Avez-vous suivi les recommandations d'import ?",
          true
        );

        if (data.notFoundInImportIds?.length > 0) {
          Modal.error({
            title: "Oh oh... Il y a eu des erreurs dans l'import des données.",
            content: (
              <>
                <p>
                  Les éléments dans votre fichier d'import font référence à d'autres éléments que
                  NOÉ n'a pas pu trouver dans votre fichier.
                </p>
                <p>
                  Alors on a fait comme on a pu, on a importé ce que l'on pouvait importer, mais on
                  ne vous garantit rien car il y a des trous dans la raquette 🤭 Vous allez sûrement
                  vous retrouver avec quelques données erronées ou incomplètes.
                </p>
                <p>
                  Voici la liste des IDs des éléments manquants dans le fichier (si vous faites une
                  recherche textuelle de ces IDs dans votre fichier, cela vous donnera sûrement des
                  pistes) :
                </p>
                <ul style={{maxHeight: 400, overflow: "scroll"}}>
                  {data.notFoundInImportIds.map((id) => (
                    <li>{id}</li>
                  ))}
                </ul>
              </>
            ),
          });
        }

        // Reload the project
        dispatch(projectsActions.updateInList(data));
        dispatch(projectsActions.setEditing(data));
        dispatch(currentProjectActions.changeProject(data));
      } catch {
        return Promise.reject();
      } finally {
        // Always set every Redux slice to init mode so it is being reloaded next time, even if there are errors
        dispatch(projectsActions.cleanProjectEntities());
      }
    },
  cleanProjectEntities: () => async (dispatch, getState) => {
    await Promise.all([
      dispatch(sessionsActions.resetContext({removeProject: true})),
      dispatch(placesActions.resetContext({removeProject: true})),
      dispatch(categoriesActions.resetContext({removeProject: true})),
      dispatch(activitiesActions.resetContext({removeProject: true})),
      dispatch(stewardsActions.resetContext({removeProject: true})),
      dispatch(registrationsActions.resetContext({removeProject: true})),
      dispatch(teamsActions.resetContext({removeProject: true})),
    ]);
  },
  rollback: (rollbackPeriod) => async (dispatch, getState) => {
    const state = getState();
    const existingId = state.projects.editing._id;

    try {
      fetchWithMessages(
        `projects/${existingId}/rollback/${rollbackPeriod}`,
        {
          noResponseData: true,
          method: "GET",
        },
        {200: "C'est bon, tout est rentré en ordre !"},
        undefined,
        true
      ).then(() => dispatch(projectsActions.cleanProjectEntities()));

      // Reload the project entities
    } catch {
      return Promise.reject();
    }
  },
  cleanProjectList: () => async (dispatch, getState) => {
    await dispatch(projectsActions.initList(false));
  },
};

export const projectsSelectors = {
  selectList: (state) => state.projects.list,
  selectEditing: (state) => state.projects.editing,
};

/**
 * Selects all cockpit charts of a project based on the project's cockpit preferences.
 * @function selectAllCockpitCharts
 * @param {Object} state - The global state of the application.
 * @param {Object} state.projects.editing - The currently editing project.
 * @returns {Array} - An array containing all the cockpit charts of the project.
 */
projectsSelectors.selectAllCockpitCharts = createSelector(
  [projectsSelectors.selectEditing],
  (project) => {
    const chartObject = project.cockpitPreferences?.charts;
    const allValues = Object.values(chartObject);
    return allValues.reduce((concatenated, array) => concatenated.concat(array), []);
  }
);

projectsSelectors.selectFlatFormComponents = createSelector(
  [projectsSelectors.selectEditing],
  (project) => {
    return flattenFieldComponents(project.formComponents);
  }
);

export const projectsReducer = projectsSlice.reducer;

export const projectsActions = {
  ...projectsSlice.actions,
  ...asyncActions,
};
