import {createSlice} from "@reduxjs/toolkit";
import {persistEntityInBackend} from "../utils/api/persistEntityInBackend";
import {currentUserActions} from "./currentUser";

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    list: [],
    init: false,
    editing: {},
  },
  reducers: {
    initList: (state, action) => {
      state.init = true;
      state.list = action.payload;
    },
    changeEditing: (state, action) => {
      state.editing = {
        ...state.editing,
        ...action.payload,
      };
    },
    setEditing: (state, action) => {
      state.editing = action.payload;
    },
  },
});

const asyncActions = {
  persist:
    (fieldsToUpdate?: any, showSuccessNotification = true) =>
    async (dispatch, getState) => {
      const state = getState();

      // If some fields are given as argument, directly take this to update the registration
      const payload = fieldsToUpdate || state.users.editing;

      return persistEntityInBackend(
        "users",
        payload,
        null, // A user will never be added with this function. They only can be added with the signUp function.
        (data) => dispatch(usersActions.setEditing(data)),
        (data) => {
          dispatch(currentUserActions.changeUser(data));
          dispatch(usersActions.setEditing(data));
        },
        showSuccessNotification
      ).catch(() => {
        /* do nothing */
      });
    },
};

export const usersSelectors = {
  selectList: (state) => state.users.list,
  selectEditing: (state) => state.users.editing,
};

export const usersReducer = usersSlice.reducer;

export const usersActions = {
  ...usersSlice.actions,
  ...asyncActions,
};
