import {createSlice} from "@reduxjs/toolkit";
import {sessionsActions} from "./sessions";
import {activitiesActions} from "./activities";
import {OFFLINE_MODE} from "../utils/offlineModeUtilities";
import {createCustomEntityAdapter} from "../utils/features/customEntityAdapter";
import {teamsActions} from "./teams";
import {persistEntityInBackend} from "../utils/api/persistEntityInBackend";
import {removeEntityInBackend} from "../utils/api/removeEntityInBackend";
import {loadEntityFromBackend} from "../utils/api/loadEntityFromBackend";
import {loadListFromBackend} from "../utils/api/loadListFromBackend";
import {
  resetDependenciesContext,
  shouldAutoRefreshDependencies,
} from "../utils/features/featuresUtilities";
import {LoadListParams} from "../utils/features/types";
import {sorter} from "../utils/sorters";
import {EntitiesSelectors} from "../utils/features/types";

const categoriesAdapter = createCustomEntityAdapter({
  selectId: (el) => el._id,
  sortComparer: (a, b) => sorter.text(a.name, b.name),
});

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: categoriesAdapter.getInitialState({
    init: {status: "idle"},
    editing: {},
  }),
  reducers: categoriesAdapter.reducers,
});

const asyncActions = {
  loadList:
    ({forceLoad, silent}: LoadListParams = {}) =>
    async (dispatch, getState) => {
      const state = getState();
      const projectId = state.currentProject.project._id;

      await loadListFromBackend(
        "categories",
        projectId,
        state.categories.init,
        () => dispatch(categoriesActions.initContext(projectId)),
        (data) => {
          dispatch(categoriesActions.initList({list: data, project: projectId}));
          // If there were some new changes (using forceLoad), also force update of dependencies
          shouldAutoRefreshDependencies(forceLoad, data) &&
            resetDependenciesContext(dispatch, sessionsActions, activitiesActions, teamsActions);
        },
        forceLoad,
        !silent
      );
    },
  loadEditing: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    return loadEntityFromBackend(
      "categories",
      entityId,
      projectId,
      state.categories.editing,
      () => dispatch(categoriesActions.setEditing({_id: "new"})),
      (data) => dispatch(categoriesActions.setEditing(data)),
      {
        notFoundAction: () =>
          OFFLINE_MODE &&
          dispatch(categoriesActions.setEditing(state.categories.entities[entityId])),
      }
    );
  },
  persist: (fieldsToUpdate?: any) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id || fieldsToUpdate.project; // If no project id, fll back on the fields given

    // If some fields are given as argument, directly take this to update the registration
    const payload = fieldsToUpdate || state.categories.editing;

    return persistEntityInBackend(
      "categories",
      {...payload, project: projectId},
      projectId,
      (data) => dispatch(categoriesActions.addToList(data)),
      (data) => {
        dispatch(categoriesActions.updateInList(data));
        resetDependenciesContext(dispatch, sessionsActions, activitiesActions);
      }
    );
  },
  remove: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    await removeEntityInBackend(
      "categories",
      entityId,
      projectId,
      categoriesSelectors.selectList(state),
      () => dispatch(categoriesActions.removeFromList(entityId))
    );
  },
  loadEditingHistory: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    !OFFLINE_MODE &&
      (await loadEntityFromBackend(
        "categories",
        `${entityId}/history`,
        projectId,
        state.categories.editing,
        null,
        (data) => dispatch(categoriesActions.changeEditing({history: data}))
      ));
  },
};

const categoriesAdapterSelectors = categoriesAdapter.getSelectors((state) => state.categories);

export const categoriesSelectors: EntitiesSelectors<any, any> = {
  selectEditing: (state) => state.categories.editing,
  selectList: categoriesAdapterSelectors.selectAll,
  selectById: categoriesAdapterSelectors.selectEntities,
  selectIsLoaded: (state) => state.categories.init.status === "loaded",
};

export const categoriesReducer = categoriesSlice.reducer;

export const categoriesActions = {
  ...categoriesSlice.actions,
  ...asyncActions,
};
