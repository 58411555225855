import {combineReducers} from "@reduxjs/toolkit";
import {currentUserReducer} from "../features/currentUser.js";
import {currentProjectReducer} from "../features/currentProject.js";
import {projectsReducer} from "../features/projects.js";
import {registrationsReducer} from "../features/registrations";
import {sessionsReducer} from "../features/sessions.js";
import {teamsReducer} from "../features/teams";
import {placesReducer} from "../features/places.js";
import {stewardsReducer} from "../features/stewards.js";
import {activitiesReducer} from "../features/activities.js";
import {usersReducer} from "../features/users.js";
// import {computingReducer} from "../features/computing";
import {categoriesReducer} from "../features/categories.js";
import {viewReducer} from "../features/view.js";
import {getConfiguredStoreAndPersistor} from "../utils/offlineModeUtilities";
import {getSentryReduxEnhancer} from "./services/sentry";

const mainReducer = combineReducers({
  currentUser: currentUserReducer,
  currentProject: currentProjectReducer,
  projects: projectsReducer,
  registrations: registrationsReducer,
  sessions: sessionsReducer,
  teams: teamsReducer,
  places: placesReducer,
  stewards: stewardsReducer,
  activities: activitiesReducer,
  users: usersReducer,
  // computing: computingReducer,
  categories: categoriesReducer,
  view: viewReducer,
});

export const [store, persistor] = getConfiguredStoreAndPersistor(
  mainReducer,
  process.env.NODE_ENV === "production" ? [getSentryReduxEnhancer()] : undefined
);
