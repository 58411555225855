import dayjs from "dayjs";
import i18next from "i18next";

export const timeFormatter = {
  time: (date) => (date ? dayjs(date).format("LT") : ""),

  timeRange: (startDate, endDate) =>
    startDate && endDate ? timeFormatter.time(startDate) + " – " + timeFormatter.time(endDate) : "",

  duration: (minutes, short = false) => {
    if (minutes < 60) return `${Math.floor(minutes)}min`;
    const hours = dayjs.utc().startOf("day").add(minutes, "minute").format("H[h]mm");
    const numberOfDays = Math.floor(minutes / 1440);
    return (
      `${numberOfDays > 0 ? `${numberOfDays} jours et ` : ""}${hours}` +
      (short ? "" : ` (${Math.floor(minutes)}min)`)
    );
  },
};

export const dateFormatter = {
  shortDate: (date) => {
    if (!date) return "";
    const parsedDate = Date.parse(date);
    return Intl.DateTimeFormat(i18next.language, {
      day: "numeric",
      month: "numeric",
    }).format(parsedDate);
  },

  longDate: (date, short = false, withYear = false) => {
    if (!date) return "";
    const parsedDate = Date.parse(date);
    let day = Intl.DateTimeFormat(i18next.language, {
      day: "numeric",
      weekday: short ? "short" : "long",
    }).format(parsedDate);
    day = day.charAt(0).toUpperCase() + day.slice(1); // capitalize day
    let month = Intl.DateTimeFormat(i18next.language, {
      month: short ? "short" : "long",
    }).format(parsedDate);
    month = month.charAt(0).toUpperCase() + month.slice(1); // capitalize month
    let year =
      withYear &&
      Intl.DateTimeFormat(i18next.language, {
        year: "numeric",
      }).format(parsedDate);
    return day + " " + month + (year ? " " + year : "");
  },

  longDateTime: (date, short, withYear) =>
    date ? dateFormatter.longDate(date, short, withYear) + " " + timeFormatter.time(date) : "",

  longDateRange: (startDate, endDate, short, withYear) => {
    if (!startDate || !endDate) return "";
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    return `${dateFormatter.longDate(start, short)} – ${dateFormatter.longDate(
      end,
      short,
      withYear
    )}`;
  },

  longDateTimeRange: (startDate, endDate, short, withYear) => {
    if (!startDate || !endDate) return "";
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    if (start.isSame(end, "day")) {
      return `${dateFormatter.longDateTime(start, short, withYear)} – ${timeFormatter.time(end)}`;
    } else {
      return `${dateFormatter.longDateTime(start, short)} – ${dateFormatter.longDateTime(
        end,
        short,
        withYear
      )}`;
    }
  },
};
